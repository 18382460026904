<template>
  <List
    title="persons"
    resource="personIdentities"
    track-by="personId"
    :basePath="basePath"
    :fields="fields"
    ref="list"
    on-row-click="edit"
    data-provider="$raaDataProvider"
    :search="true"
    search-placeholder="Search by name, email, identity email"
    :initial-filter="{ deletedEntity: 'all' }"
    :filter-mapper="mapFilterToQuery"
    :pageSize="20"
    infinity-scroll
  >
    <template v-slot:filters="filterScope">
      <div class="form-row form-narrow">
        <SelectInput
          name="deletedEntity"
          :label="entityFilterName"
          :options="entityFilterOptions"
          no-clear
        />
        <TextField name="personId" label="person id" :validate="uuid" />
        <TextField name="identityId" label="identity id" :validate="uuid" />
        <TextField name="cognitoIdentityId" label="cognito id" :validate="uuid" />
      </div>
      <div class="form-row justify-end">
        <button @click.stop="filterScope.reset()" class="btn-primary btn-transparent mr-8">Reset</button>
        <button class="btn-primary">Filter</button>
      </div>
    </template>
    <template v-slot:inline-actions="actionsScope">
      <button
        class="btn-action-warning"
        v-if="!actionsScope.record.deletedAt"
        @click.stop="deletePerson(actionsScope.record)"
      >
        <icon class="w-5 h-5 mr-1" name="trash"/>
      </button>
    </template>
  </List>
</template>

<script>
  import NotifyMixin from "@/mixins/NotifyMixin";
  import ConfirmationMixin from "@/mixins/ConfirmationMixin";
  import ModalNavigation from "@/mixins/ModalNavigation";
  import ValidatorMixin from '@/components/form/ValidatorMixin';
  import List from "@/components/auth/list/List";
  import Icon from "@/components/ui/Icon";
  import TextField from "@/components/form/TextField";
  import SelectInput from "@/components/form/SelectInput";
  import UserField from "@/components/auth/list/fields/UserField";
  import DateTimeField from "@/components/auth/list/fields/DateTimeField";
  import CognitoField from "@/components/auth/list/fields/CognitoField";
  import EntityFilterMixin from "@/components/auth/EntityFilterMixin";

  export default {
    name: "PersonsList",
    components: {
      List,
      Icon,
      TextField,
      SelectInput,
    },
    mixins: [ModalNavigation, ConfirmationMixin, EntityFilterMixin, NotifyMixin, ValidatorMixin],
    computed: {
      basePath: function () {
        return this.$route.path;
      },
    },
    data: function () {
      return {
        fields: [
          {
            name: UserField,
            title: 'contact',
            sortField: 'name',
            switch: {
              getName: (rowData) => rowData.name,
              getEmail: (rowData) => rowData.contactEmails[0]?.email,
            },
          },
          {
            name: CognitoField,
            title: 'cognito identity',
            sortField: 'email',
            switch: {
              getIdentity: (rowData) => ({ email: rowData.email, phone: rowData.phone })
            },
          },
          {
            name: DateTimeField,
            sortField: 'createdAt',
            title: 'Registered',
            switch: {
              source: 'createdAt'
            },
            titleClass: 'text-center',
            dataClass: 'text-center',
          },
          {
            name: 'inline-actions',
            title: '',
            width: "20%",
          }
        ]
      };
    },
    methods: {
      mapFilterToQuery({ deletedEntity, ...rest } = {}) {
        const deletedFilter = this.mapEntityFilterToQuery(deletedEntity);
        return {
          ...rest,
          ...deletedFilter,
        };
      },
      deletePerson({personId}) {
        /* There is a flat list with person-identity match. One person to many identities is not implemented atm.
        *  TODO: update what to delete when linking many identities to one person is implemented.
        * */
        this.requestConfirmation({
          confirmationMessage: 'Are you sure you want to delete this person?',
          confirmBtnText: 'yes',
          cancelBtnText: 'no',
          confirmationType: 'warning'
        })
          .then(confirmed => confirmed
            ? this.$raaDataProvider.delete('persons', {id: personId})
              .then(() => {
                this.$refs.list.doSearch();
                this.notifySuccess('person deleted');
              })
              .catch(error => this.notifyError(error.message))
            : null
          );
      }
    }
  }
</script>
