<template>
  <th
    v-if="isHeader"
    class="vuetable-th-component-username"
    v-html="title"
  />
  <td v-else class="vuetable-td-component-username">
    <div class="text-sm font-600 mb-1">{{getName(rowData)}}</div>
    <div class="text-sm user-email">{{getEmail(rowData)}}</div>
  </td>
</template>

<script>
  import VuetableFieldMixin from 'vue3-vuetable/src/components/VuetableFieldMixin.vue';

  export default {
    name: 'UserField',
    mixins: [VuetableFieldMixin],
    methods: {
      getName(rowData) {
        const getName = this.rowField.switch?.getName;
        return getName ? getName(rowData) : `${rowData.firstName} ${rowData.lastName}`;
      },
      getEmail(rowData) {
        const getEmail = this.rowField.switch?.getEmail;
        return getEmail ? getEmail(rowData) : rowData.email;
      }
    }
  };
</script>
<style scoped>
  .user-email {
    color: #848484;
  }
</style>
