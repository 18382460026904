<template>
  <th
    v-if="isHeader"
    class="vuetable-th-component-cognito"
    v-html="title"
  />
  <td v-else class="vuetable-td-component-cognito">
    <div v-if="noIdentity">-</div>
    <div v-else>
      <div>{{identity.email}}</div>
      <div class="text-xs">{{identity.phone}}</div>
    </div>
  </td>
</template>

<script>
  import {isEmpty} from 'lodash-es';
  import VuetableFieldMixin from 'vue3-vuetable/src/components/VuetableFieldMixin.vue';

  export default {
    name: 'CognitoField',
    mixins: [VuetableFieldMixin],
    computed: {
      identity() {
        const getIdentity = this.rowField.switch?.getIdentity;
        return getIdentity ? getIdentity(this.rowData) : {};
      },
      noIdentity() {
        return isEmpty(this.identity);
      }
    },
  };
</script>
